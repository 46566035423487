import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import cookie from "react-cookies";
import { MOBILE_WEB_BASE_URL, SME_WEB_BASE_URL } from '../../unified/webbase'; 
import Swal from "sweetalert2";
function SmeToggleData() {
    const token = cookie.load("token")
    const [users,setUsers] = useState([])
    const [search, setSearch] = useState('')
    const [open, setOpen] = useState(false)

    useEffect(() => { 
        axios
            .get(
                SME_WEB_BASE_URL + "/onboarding/get-all-partner",
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "2000" && res.data.data !== null) {
                    console.log(res.data.data);
                   setUsers(res.data.data.reverse())
                } else {
                    toast.error("No data found");
                    setUsers([])
                }
            })
            .catch((err) => {
                
                toast.error("Error fetching transactions");
            }).finally(() => {
                
            });
    },[ token])
    
    const toggleMerchant = (name, status) => {
        console.log(name)
        Swal.fire({
          title: 'Are you sure?',
          text: "You are about to change the status of this SME merchant.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, toggle it!',
        }).then((result) => {
          if (result.isConfirmed) {
            // Show loading toast
            const id = toast.loading("Please wait...");
            
            axios
            .get(
                MOBILE_WEB_BASE_URL + `/toggle-sme-data?partnerName=${name}&status=${status}`,
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
              .then((res) => {
                if (res.data.status === "2000") {
                  toast.update(id, {
                    render: res.data.description,
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                  });
      
                  // Optional: reload after delay
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                } else {
                  toast.update(id, {
                    render: res.data.description,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                  });
                }
              })
              .catch((err) => {
                toast.update(id, {
                  render: err.message || "An error occurred. Please try again.",
                  type: "error",
                  isLoading: false,
                  autoClose: 2000,
                });
              });
          }
        });
      };
      

    const filteredData = users.filter((person) => {
        const searchLowerCase = search.toLowerCase();
        const fullName = `${person.firstName} ${person.middleName} ${person.lastName}`.toLowerCase();
        return fullName.includes(searchLowerCase) || person.emailAddress.toLowerCase().includes(searchLowerCase);
      });
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "NGN",
      });
  return (
    
    <main className="page-content">
   
    <ToastContainer
         position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
      />
    <h3 className=' mb-2'>SME Partner</h3> 
   
   
<div className=' mt-6'>
        <div className=' bg-white mt-4'>
        <div class="table-responsive">
                            
                            <table className="table align-middle mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th>Merchant name</th>
                                        <th>Date Created</th>
                                        <th>status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   
                                    {filteredData.length !== 0 ? (
                                        filteredData.map((e, index) => (
                                            <tr key={index}>
                                                <td>{e?.merchantName}</td>
                                                
                                                <td> {e.createdDate !== undefined
                                                            ? new Date(e.createdDate).toLocaleString()
                                                            : 0}</td>
                                               
                                               
                                                            <td>
                                                                <div className="table-actions d-flex align-items-center gap-3 ">
                                                                <div></div>
                                                                <div
                                                                    className= {e?.status === "active"?`text-success`: `text-danger `}
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="bottom"
                                                                    title= {e?.status === "active"?`Activate provider`: `Deactivate provider `}
                                                                    onClick={() => {
                                                                        toggleMerchant(e.merchantName,e.status === "active"?`inactive`: `active`)
                                                                    } }
                                                                    >
                                                                    <i className="bi bi-toggle-on"></i>
                                                                </div>
                                                                </div>
                                                            </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr colSpan="7">
                                            <td>No User record found.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div> 
            </div>
       </div>
    </main>
  )
}

export default SmeToggleData