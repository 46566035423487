import axios from "axios";
import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import { WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { CSVLink } from "react-csv";
import Multiselect from 'multiselect-react-dropdown';

class ReportSummary extends React.Component {
    constructor() {
        super();
        this.state = {
            token: cookie.load("token"),
            dealerId: cookie.load("dealerId"),
            userRole: cookie.load("userRole"),
            userGroup: cookie.load("userGroup"),
            walletBalance: cookie.load("walletBalance"),
            selectedTable: "Monthly",
            startDate: new Date(),
            endDate: new Date(),
            fromDate: this.makeShortDate(new Date()),
            toDate: this.makeShortDate(new Date()),
            transactions: [],
            filterValue: '',
            merchantEmail:'',
            walletHistory: [],
            dataByDate: {},
            isLoading: false,
            currentPage: 1,
            network: [
                { id: 1, name: "MTN" },
                { id: 2, name: "Glo" },
                { id: 3, name: "9mobile" },
                { id: 4, name: "Airtel" },
            ],
            filterOptions: [
                { id: 1, name: "Network" },
                // { id: 2, name: "MSISDN" },
                { id: 3, name: "Beneficiary No" },
                { id: 4, name: "Merchant ID" },
                { id: 5, name: "Customer Reference" }
            ],
            selectedFilters: [],
            marchantList:[],
            msisdn: "",
            beneficiaryPhone: "",
            customerReference: "",
            selectedNetwork: "",
            merchantId: "",
            status: "",

        };
        
        if (this.state.userGroup == "MERCHANT") {

            this.state.filterOptions = [
                { id: 1, name: "Network" },
                { id: 2, name: "MSISDN" },
                { id: 3, name: "Beneficiary No" },
                { id: 4, name: "Merchant ID" },
                { id: 5, name: "Customer Reference" },
                { id: 6, name: "Mobile App" }
            ]
        }
        this.setState({})
        //binding this to event-handler functions
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.getTransactions = this.getTransactions.bind(this);
        this.getDailyTransactions = this.getDailyTransactions.bind(this);
        this.getWalletBalance = this.getWalletBalance.bind(this);
        this.onSelectFilter = this.onSelectFilter.bind(this);
        this.onRemoveFilter = this.onRemoveFilter.bind(this);
        this.makeShortDate = this.makeShortDate.bind(this);
       
    }

    onSelectFilter(selectedList, selectedItem) {
        this.setState({
            selectedFilters: selectedList
        })
    }

    onRemoveFilter(selectedList, removedItem) {
        this.setState({
            selectedFilters: selectedList
        })
    }
    makeShortDate(datew) {
        datew = new Date(datew);
        let yy = datew.getFullYear();
        let mm = datew.getMonth();
        let dd = datew.getDate();
        let shortDate = `${yy}-${mm < 10 ? 0 : ""}${mm + 1}-${dd < 10 ? 0 : ""
            }${dd}`;
        return shortDate;
    }

    setStartDate(date) {
        let datew = this.makeShortDate(date);

        this.setState({
            startDate: date,
            fromDate: datew,
        });
    }

    setEndDate(date) {
        let datew = this.makeShortDate(date);

        this.setState({
            endDate: date,
            toDate: datew,
        });
    }

    getTransactions = () => {
       
        axios
            .get(WEB_BASE_URL + '/dealer/daily-summarry-dealer?endDate=2024-12-08&startDate=2024-12-08&statusCode=200', {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.description === "No data available") {

                } else {
                    const trans = res.data.data.data;
                    this.setState({ transactions: trans });
                }
            })
            .catch((err) => {
                toast.error("Error fetching transactions");
            });
    };

   

    getDailyTransactions = () => {
        let datee = this.makeShortDate(this.state.startDate);
        let yesterday = this.makeShortDate(this.state.endDate);
        let api = "";
        if (this.state.userGroup === "MERCHANT") {
            api = "/merchant/daily-summarry-merchant";
        } else {
            api = "/dealer/daily-summarry-dealer";
        }

        axios
            .get(WEB_BASE_URL
                +
                api
                 + '?startDate=' + datee + "&endDate="+ yesterday+"&statusCode=200" ,
                //  
                  {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.status == "4000") {
                }
                else {
                   
                   
                    this.setState({ transactions: res.data.data });
                }
            })
            .catch((err) => {
                toast.error("Error fetching transactions");
                console.log(err)
            });
    };
    

   


    getWalletBalance() {
        axios
            .get(WEB_BASE_URL + "/onboarding/account-balance", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                cookie.save("walletBalance", res.data.data.entBalance, {
                    path: "/",
                    expires: new Date(Date.now() + 60 * 30 * 1000),
                });

                this.setState({ walletBalance: res.data.data.entBalance ? res.data.data.entBalance : 0 });
            })
            .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
    }

  

   
    handleInputChange = (event) => {
        const { value } = event.target;
        this.setState({ filterValue: value }, () => {
            this.getDailyTransactions(); // Call API again after state update
        });
    };
    componentDidMount() {
        this.getDailyTransactions();
        this.getWalletBalance();
        
    }
    handleSelectChange = (event) => {
        const selectedBusinessEmail = event.target.value;
          this.setState({
            merchantEmail: selectedBusinessEmail
          });
      };
      getLast20Chars = (str) => {
        if (!str) {
          return '';
        }
        if (str.length <= 40) {
          return str;
        }
        return str.slice(-40);
      }
    render() {
        var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "NGN",
        });

        const pageSize = 50;
        let totalPages = 0;
        if (this.state.transactions.length !== 0) {
            totalPages = Math.ceil(this.state.transactions.length / pageSize);
        }

        const handlePageChange = (page) => {
            this.setState({ currentPage: page });
        }

        let transactionsToDisplay = this.state.transactions.slice((this.state.currentPage - 1) * pageSize, this.state.currentPage * pageSize);
        return (
            <main className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                 Daily Report summary
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary">
                                Wallet Balance: &#8358;{this.state.walletBalance}
                            </button>
                        </div>
                    </div>
                </div>
 
                <div className="card">
                    <div className="card-body">
                        <form className="row">
                            <div className="row">
                                <div className="col-3">
                                    <label className="form-label">Start Date:</label>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onSelect={(date) =>{
                                            this.setStartDate(date)
                                            this.setState({ filterValue: date }, () => {
                                                    this.getDailyTransactions(); // Call API again after state update
                                                });
                                        }}
                                        onChange={(date) =>{
                                            this.setStartDate(date)
                                            this.setState({ filterValue: date }, () => {
                                                    this.getDailyTransactions(); // Call API again after state update
                                                });
                                        }}
                                        maxDate={new Date()}
                                    />
                                </div>      
                                <div className="col-3">
                                    <label className="form-label">End Date:</label>
                                    <DatePicker
                                        selected={this.state.endDate}
                                        onSelect={(date) =>{
                                            this.setEndDate(date)
                                            this.setState({ filterValue: date }, () => {
                                                    this.getDailyTransactions(); // Call API again after state update
                                                });
                                        }}
                                        onChange={(date) =>{
                                            this.setEndDate(date)
                                            this.setState({ filterValue: date }, () => {
                                                    this.getDailyTransactions(); // Call API again after state update
                                                });
                                        }}
                                        maxDate={new Date()}
                                    />
                                </div>      
                                
                            </div>
                         
                        </form>
                    </div>
                </div>

                <div className="card">
                   
                    <div class="card-body">
                        <div class="table-responsive">
                            <table className="table align-middle mb-0">
                                <thead className="table-light">
                                    <tr>
                                       
                                        <th>Face value</th>
                                        <th>Discounted Amount</th>
                                        <th>Amount Debited</th>
                                        <th>Network</th>
                                        <th>Account id</th>
                                        <th>{this.state.userGroup === "MERCHANT"?'Merchant ID':'Dealer ID'} </th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionsToDisplay.length !== 0 ? (
                                        transactionsToDisplay.map((e, index) => (
                                            <tr key={index}>
                                                <td>{e?.faceValue}</td>
                                                <td>{formatter.format(e?.discountedAmount)}</td>
                                                <td>{formatter.format(e?.amountDebited)}</td>
                                                <td>{e?.network}</td>
                                                <td>{e?.accountid}</td>

                                                <td>{e?.dealerId} </td>
                                                <td>
                                                    {e.transDate !== undefined
                                                        ? new Date(e.transDate).toLocaleString()
                                                        : 0}
                                                </td>
                                               
                                            </tr>
                                        ))
                                    ) : (
                                        <tr colSpan="7">
                                            <td>No Record.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div style={{
                            overflowX: "scroll",
                        }}>
                            <nav class="float-end mt-3">
                                <ul class="pagination">
                                    {Array.from(Array(totalPages), (e, i) => {
                                        let pageNumber = i + 1;
                                        return (
                                            <li class={"page-item " + this.state.currentPage == pageNumber && "active"}>
                                                <a class="page-link" href="/2"
                                                    style={{
                                                        backgroundColor: this.state.currentPage == pageNumber ? "#0d6efd" : "",
                                                        color: this.state.currentPage == pageNumber ? "#fff" : ""
                                                    }}
                                                    onClick={
                                                        (e) => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                currentPage: pageNumber
                                                            })
                                                        }
                                                    }
                                                >
                                                    {pageNumber}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main >
        );
    }
}

export default ReportSummary;
