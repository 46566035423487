import  React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import cookie from "react-cookies";
import { WEB_BASE_URL } from '../../unified/webbase';
import axios from 'axios';
import { useEffect } from 'react';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  boxShadow: 24,
};


export default function VirtualAccountModal({open, handleClose}) {
     const [accNo, setAccNo] = useState('')
     const [accName, setAccName] = useState('')
     const [bank, setBank] = useState('')
     const [virtualAcc, setVirtualAccount] = useState({})
    // const accNo = cookie.load("accNo")
    // const accName = cookie.load("accNa")
    const token = cookie.load("token")

    useEffect(() => {
       
      let api = "";
      api = "/onboarding/get-dedicated-account";
      axios
          .get(
              WEB_BASE_URL +
              api ,
              {
                  headers: {
                      authorization: token,
                  },
              }
          )
          .then((res) => {
              if (res.data.status === "2000" && res.data.data !== null) {
                  // setAccName(res.data.data.accountName);
                  // setAccNo(res.data.data.accountNumber);
                  setVirtualAccount(res.data.data)
              } else {
                  toast.error("No data found");
                  
              }
          })
          .catch((err) => {
              console.log(WEB_BASE_URL + " " + err);
              toast.error("Error fetching transactions");
          }).finally(() => {
              
          });
  },[ token])
    const handleClick = async (acc) => {
      try {
        await navigator.clipboard.writeText(acc);
        toast.success('Account number copied')
        toast( {
            render:'Account number copied',
            type: "success",
          });
      
        // Reset copied state after a few seconds
        setTimeout(() => {
         
        }, 2000);
      } catch (error) {
        console.error('Failed to copy:', error);
      }
    };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg  p-4 pt-10'> 
          
            <div className=' '>
           
                <div className=' d-flex justify-content-end mb-3'>
                   
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-black cursor-pointer text-[38px]' fontSize="large"/>
                    
                    </div>
                </div>
                <div className=' p-5 card'
                 style={{
                        backgroundColor: "#D3EFF9",
                        borderRadius:  "16px",
                      }}>
                      <h3 className='text-center pb-3'>Virtual Account</h3>
                      <div className=' row g-3'>

                     
                      <div className=' col-6 mb-3'>
                      <h6 className=' mb-3'>Vas Virtual wallet</h6>
                      <div className=' mb-3'>
                      <h6 >Bank:</h6>
                      <h6 > {virtualAcc?.virtualAccountVas?.bankName??''}</h6>
                      </div>
                      <div className=' mb-3'>
                      <h6 >Account name:</h6>
                      <h6 > {virtualAcc?.virtualAccountVas?.accountNameVas??''}</h6>
                      </div>
                      <div className=' mb-3'>
                      <h6 >Account number:</h6>
                      <h5 >{virtualAcc?.virtualAccountVas?.accountNumberVas??''} <ContentCopyIcon onClick={() =>{
                      handleClick(virtualAcc?.virtualAccountVas?.accountNumberVas??'')
                      }} style={{
                        color: "blue",
                        
                      }}  /></h5>
                      </div>
                    
                      </div>

                      <div className=' col-6 mb-3'>
                      <h6 className=' mb-3'>VTU Virtual wallet</h6>
                      <div className=' mb-3'>
                      <h6 >Bank:</h6>
                      <h6 > {virtualAcc?.virtualAccount?.bankName??''}</h6>
                      </div>
                      <div className=' mb-3'>
                      <h6 >Account name:</h6>
                      <h6 > {virtualAcc?.virtualAccount?.accountName??''}</h6>
                      </div>
                      <div className=' mb-3'>
                      <h6 >Account number:</h6>
                      <h5 >{virtualAcc?.virtualAccount?.accountNumber??''} <ContentCopyIcon onClick={() =>{
                      handleClick(virtualAcc?.virtualAccount?.accountNumber??'')
                      }}  style={{
                        color: "blue",
                        
                      }}  /></h5>
                      </div>
                    
                      </div>
                      </div>
                </div>
             
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}